import React, { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { isEmpty, isNil } from "lodash";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Dialog, IconButton, Link, Typography } from "@material-ui/core";

import { isArray } from "../../utils/array";
import { Paths } from "../../constants/paths";
import { useMobile, useTab } from "../../hooks/mobile";
import { useAccessToken } from "../../hooks/common";
import { useUserStore } from "../../stores/user";
import { parseApiError } from "../../helpers/error";
import SectionHeader from "../../components/Public/Bookings/SectionHeader";
import { parseQueryString, stringifyParams } from "../../helpers/string";
import { Job, PaginatedResponse2 } from "../../models";
import { getValue } from "../../utils/object";
import { BOOKINGS_SECTION_VALUES, TOGGLE_VALUES } from "../../constants/prodashboard";
import PaginationControl from "../../components/PaginationControl";
import { SetLocationModal } from "../../components/Modals/MapModal/SetLocationModal";
import FiltersContainer from "../../components/Public/Bookings/FiltersContainer";
import {
  AlignItems,
  Display,
  FlexDirection,
  JustifyContent,
  Position,
  Spacing,
} from "../../components/v2/Styled/enum";
import { Colors } from "../../constants/colors";
import JobDetailPublicV3 from "./Bookings/JobDetailPublicV3";
import AvailableBookingCardHolder from "./Bookings/AvailableBookingCardHolder";
import "../../components/ScrollBar.css";
import Navigation from "../../components/Public/Bookings/Header";
import BookingsMap from "./Bookings/BookingsMap";
import CloseIcon from "../../images/24_carat-left.png";
import ShareIcon from "../../images/share-icon.svg";
import useShareSheet from "../../hooks/shareSheet";
import { getDefaultSiteURL } from "../../constants/common";
import { NoItemsPlaceholder } from "./Bookings/NoItemsPlaceholder";
import LoginModal from "../Login/LoginModal";
import SignUpModal from "../SignUp/SignUpModal";
import ForgotPasswordModal from "../ForgotPassword/ForgotPasswordModal";
import { useAlertStore } from "../../stores/alert";

interface BookingFilterState {
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  location?: any | null;
  serviceType?: number[] | null;
  order: any | null;
  distance?: number | null;
  id?: number | null;
}

export default function PublicBookings() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const isMobile = useMobile();
  const isTab = useTab();
  const accessToken = useAccessToken();
  const query = new URLSearchParams(location.search);
  const { user, fetchMe, isFetchingUser } = useUserStore();
  const reservedQuery = parseQueryString(location.search);

  const [selectedJobId, setSelectedJobId] = useState<number | null>(
    reservedQuery.id ? parseInt(reservedQuery.id as string) : null
  );
  const [openJobDetailDialogue, setOpenJobDetailDialogue] = useState(
    reservedQuery.id ? true : false
  );
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [forgotPasswordPrefillEmail, setForgotPasswordPrefillEmail] = useState("");
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  if (params && params.id) {
    history.replace("/my-bookings/" + params.id);
  }

  useEffect(() => {
    if (user && !user.therapistprofile) {
      history.push(Paths.ProSignup);
    }
  }, [user]);

  useEffect(() => {
    // Find the existing robots meta tag or create a new one
    let metaTag = document.querySelector('meta[name="robots"]');
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "robots");
      document.head.appendChild(metaTag);
    }

    // Set the content to index, follow
    metaTag.setAttribute("content", "index, follow");

    // Clean up if necessary (optional)
    return () => {
      if (metaTag) {
        metaTag.setAttribute("content", "noindex, nofollow");
      }
    };
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetchMe();
      //history.push(Paths.ProDashboardBookings);
    }
  }, [accessToken]);

  const perPage = 10;
  const shareSheet = useShareSheet();

  const [currentPage, setCurrentPage] = useState(0);
  const [showPagination, setShowPagination] = useState(true);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  let [filter, setFilter] = useState<BookingFilterState>({
    startDate: (reservedQuery.startDate as string) || null,
    endDate: (reservedQuery.endDate as string) || null,
    location: (reservedQuery.location && JSON.parse(reservedQuery.location as string)) || null,
    serviceType: reservedQuery.serviceType
      ? //@ts-ignore
        isArray(reservedQuery.serviceType)
        ? //@ts-ignore
          reservedQuery.serviceType.map((serviceId: any) =>
            typeof serviceId === "string" ? parseInt(serviceId) : serviceId
          )
        : [parseInt(reservedQuery.serviceType as string)]
      : reservedQuery.serviceType || null,
    order: (reservedQuery.order && JSON.parse(reservedQuery.order as string)) || null,
    // @ts-ignore
    distance: (reservedQuery.distance && parseInt(query.get("distance") as string)) || null,
    id: reservedQuery.id,
  });

  const apiUrl = useMemo(() => {
    const { id, ...restFilters } = filter;
    if (!accessToken) {
      return `/api/v2/therapists/bookings/public/new?filters=${JSON.stringify(restFilters)}&currentPage=${currentPage + 1}&perPage=${perPage}`;
    } else {
      return `/api/v2/therapists/bookings/new?accessToken=${accessToken}&filters=${JSON.stringify(restFilters)}&currentPage=${currentPage + 1}&perPage=${perPage}`;
    }
    return null;
  }, [filter, currentPage]);

  const { data, mutate, revalidate, error: apiError } = useSWR(apiUrl);
  const parsedApiError = apiError && parseApiError(apiError);

  useEffect(() => {
    setApiErrorMessage(parsedApiError);
  }, [parsedApiError]);

  const items = useMemo(() => {
    let sectionItems: any = [];
    sectionItems = (getValue(data, "jobs") || []) as Job[];
    sectionItems = sectionItems.filter((item: any) => !!item);
    return sectionItems;
  }, [data]);

  useEffect(() => {
    if (selectedJobId === null && items.length > 0) {
      setSelectedJobId(getValue(items[0], "id", null));
    }
  }, [items]);

  const pages = useMemo(() => {
    const paginatedResponse = data && (data as PaginatedResponse2);
    if (paginatedResponse) {
      const { pageCount } = paginatedResponse;
      return pageCount;
    }
    return 0;
  }, [data]);

  const noItems = !isNil(data) && isEmpty(items);

  const placeholder = "No bookings found";

  const handleJobOpen = useCallback(
    ({ jobId }: { jobId: number | null }) => {
      if (jobId !== null) {
        history.push(`pro/bookings/${jobId}`);
      }
    },
    [history]
  );

  const [isMapSelected, setIsMapSelected] = useState(false);

  const handleFilterApplied = useCallback((field: string | string[], value: any) => {
    const filterObj: any = {};
    if (typeof field === "string") {
      filterObj[field] = value;
    } else if (Array.isArray(field)) {
      field.forEach((f, index) => {
        filterObj[f] = value[index];
      });
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...filterObj,
    }));
  }, []);

  const clearFilters = useCallback(() => {
    setFilter({
      endDate: null,
      location: null,
      order: null,
      serviceType: null,
      startDate: null,
      distance: null,
    });
  }, []);

  useEffect(() => {
    if (filter.location && typeof filter.location !== "string") {
      filter.location = JSON.stringify(filter.location);
    }
    if (!filter.serviceType || filter.serviceType.length <= 0) {
      filter.serviceType = null;
    }
    if (filter.order && typeof filter.order !== "string") {
      filter.order = JSON.stringify(filter.order);
    }
    setCurrentPage(0);
    history.replace(
      `?${stringifyParams(
        { ...filter },
        {
          skipEmptyString: true,
          skipNull: true,
          skipEmptyArray: true,
          skipEmptyObject: true,
        }
      )}`
    );
  }, [filter, history]);

  const handleLoginClicked = () => {
    setLoginModalOpen(true);
  };

  const handleSignUpClicked = () => {
    setSignUpModalOpen(true);
  };

  const subTitle = useMemo(
    () => (
      <Box>
        <Typography>
          {" "}
          Find flexible shifts, nearby jobs, and bookings that align with your schedule—all in one
          convenient place.{" "}
          <Link onClick={handleLoginClicked} underline="always" style={{ color: Colors.Dusk }}>
            Log in
          </Link>{" "}
          to apply and secure your next booking today! No account?{" "}
          <Link onClick={handleSignUpClicked} underline="always" style={{ color: Colors.Dusk }}>
            Sign up
          </Link>{" "}
          now and start accessing available jobs instantly!{" "}
        </Typography>
      </Box>
    ),
    []
  );

  const handleCloseDialog = useCallback(() => {
    setOpenJobDetailDialogue(false);
  }, []);

  const [selectedSegment, setSelectedSegment] = useState(0);

  const handleJobShare = () => {
    shareSheet.share({
      title: "Blys",
      text: "Check out this job on Blys!",
      url: `${getDefaultSiteURL()}/bookings?id=${selectedJobId}`,
    });
  };

  function handleJobClicked(jobId: any) {
    history.replace(`?${stringifyParams({ id: jobId })}`);
    setSelectedJobId(jobId);
    filter.id = jobId;
    setOpenJobDetailDialogue(true);
  }

  return (
    <Box>
      <Helmet>
        <title>{"New Bookings | Blys"}</title>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta
          name="description"
          content="Easily view and manage your new bookings on Blys. Stay updated on upcoming appointments and streamline your schedule, all in one place."
        ></meta>
      </Helmet>
      <Navigation />
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        justifyContent={JustifyContent.center}
        alignItems={AlignItems.center}
        gridGap={Spacing.S6}
        paddingBottom={Spacing.S8}
        borderBottom={"1px solid #DCDFE6"}
      >
        <SectionHeader
          title="Available jobs"
          subtitle={subTitle}
          segments={TOGGLE_VALUES}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
        />
        <FiltersContainer
          filter={filter}
          handleFilterApplied={handleFilterApplied}
          clearFilter={clearFilters}
        ></FiltersContainer>
      </Box>
      <Box px={isMobile ? Spacing.S5 : Spacing.S0} bgcolor={Colors.Athens}>
        {(!apiErrorMessage && !noItems && (
          <Box width="100%" justifyItems={JustifyContent.center} alignContent="center">
            {items && items.length ? (
              selectedSegment === 0 ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: FlexDirection.Row,
                    justifyContent: JustifyContent.center,
                    width: isMobile ? "100%" : "80%",
                    height: 691,
                    margin: "auto",
                    marginBottom: Spacing.S2,
                  }}
                >
                  <Box
                    id={"available-booking-card-holder"}
                    paddingRight={!(isMobile || isTab) && "5px"}
                    className={isMobile || isTab ? "" : "scroll-vertical"}
                    style={!(isMobile || isTab) ? { overflowY: "scroll" } : {}}
                    minWidth={isMobile || isTab ? undefined : "391px"}
                    width={isMobile || isTab ? "100%" : "40%"}
                    bgcolor={!(isMobile || isTab) && Colors.White}
                  >
                    <AvailableBookingCardHolder
                      jobs={items}
                      selectedJobId={selectedJobId as number}
                      onClick={(jobId: any) => {
                        handleJobClicked(jobId);
                      }}
                    />
                    {pages > 1 && showPagination && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          paddingTop: Spacing.S3,
                          paddingBottom: "24px",
                          paddingRight: "16px",
                        }}
                        borderRight={!(isMobile || isTab) && "1px solid #e0e2e7"}
                        borderLeft={!(isMobile || isTab) && "1px solid #e0e2e7"}
                        borderBottom={!(isMobile || isTab) && "1px solid #e0e2e7"}
                        bgcolor={Colors.White}
                        borderRadius={isMobile || isTab ? Spacing.S2 : Spacing.S0}
                      >
                        <PaginationControl
                          currentPage={currentPage}
                          pages={pages}
                          onPrevious={() => {
                            if (currentPage > 0) {
                              setCurrentPage(currentPage - 1);
                            }
                          }}
                          onNext={() => {
                            if (currentPage < pages - 1) {
                              setCurrentPage(currentPage + 1);
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  {!isMobile && !isTab && selectedJobId && (
                    <Box
                      width={"100%"}
                      borderRight={"1px solid #e0e2e7"}
                      paddingRight={"5px"}
                      bgcolor={Colors.White}
                    >
                      <Box
                        height={"691px"}
                        minWidth={"545px"}
                        marginLeft={"5px"}
                        className="scroll-vertical"
                      >
                        <JobDetailPublicV3
                          jobId={selectedJobId}
                          handleJobOpen={handleJobOpen}
                          handleJobShare={handleJobShare}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: FlexDirection.Row,
                    justifyContent: JustifyContent.center,
                    width: "100%",
                    height: 691,
                    margin: 0,
                  }}
                  bgcolor={Colors.White}
                >
                  <Box width={"100%"} bgcolor={Colors.Athens}>
                    <BookingsMap bookings={[]} jobs={items} tab={BOOKINGS_SECTION_VALUES.NEW} />
                  </Box>
                </Box>
              )
            ) : (
              <NoItemsPlaceholder
                placeholder={placeholder}
                subtitle={"Try adjusting your search area, filters, or sorting options, or"}
                actionText={"clear them all."}
                onActionHandle={clearFilters}
              />
            )}
          </Box>
        )) || (
          <NoItemsPlaceholder
            placeholder={placeholder}
            subtitle={"Try adjusting your search area, filters, or sorting options, or"}
            actionText={"clear them all."}
            onActionHandle={clearFilters}
          />
        )}
      </Box>

      <SetLocationModal
        open={isMapSelected}
        onClose={() => {
          setIsMapSelected(false);
        }}
        setIsMapSelected={setIsMapSelected}
        handleAddressSelected={({ address }) => {
          handleFilterApplied("location", address);
        }}
      />
      <Dialog
        open={openJobDetailDialogue && (isMobile || isTab)}
        fullScreen={isMobile || isTab}
        onClose={handleCloseDialog}
      >
        <Box
          paddingTop={Spacing.S14}
          paddingLeft={Spacing.S1}
          paddingRight={Spacing.S10}
          position={Position.Relative}
          style={{ borderBottom: `1px solid ${Colors.LightPeriwinkle}` }}
        >
          <Box gridGap={Spacing.S4} display={Display.Flex}>
            <Box position={Position.Absolute} top={Spacing.S2}>
              <IconButton onClick={handleCloseDialog}>
                <img src={CloseIcon} alt="closeIcon" width={"24px"} height={"24px"} />
              </IconButton>
            </Box>
            <Box position={Position.Absolute} top={Spacing.S2} right={Spacing.S4}>
              {shareSheet.isSupported && (
                <Box
                  width={Spacing.S5}
                  height={Spacing.S5}
                  padding={Spacing.S2}
                  border={1}
                  borderColor={Colors.LightPeriwinkle}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={Spacing.S2}
                >
                  <img
                    src={ShareIcon}
                    style={{ cursor: "pointer", width: Spacing.S5, height: Spacing.S5 }}
                    onClick={handleJobShare}
                    alt={"share"}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="scroll-vertical">
          {selectedJobId && (
            <JobDetailPublicV3 jobId={selectedJobId} handleJobOpen={handleJobOpen} />
          )}
        </Box>
      </Dialog>
      {isNil(user) && (
        <LoginModal
          open={loginModalOpen}
          onClose={() => {
            setLoginModalOpen(false);
          }}
          onLoggedIn={() => {
            fetchMe();
            setLoginModalOpen(false);
          }}
          onForgotPassword={(email) => {
            setForgotPasswordPrefillEmail(email);
            setLoginModalOpen(false);
            setForgotPasswordModalOpen(true);
          }}
          onCreateAccount={() => {
            setLoginModalOpen(false);
            setSignUpModalOpen(true);
          }}
          redirect={false}
        />
      )}
      {isNil(user) && (
        <SignUpModal
          open={signUpModalOpen}
          onLogin={() => {
            setSignUpModalOpen(false);
            setLoginModalOpen(true);
          }}
          onCreatedAccount={() => {
            fetchMe();
            setSignUpModalOpen(false);
          }}
          onClose={() => setSignUpModalOpen(false)}
        />
      )}
      {isNil(user) && (
        <ForgotPasswordModal
          open={forgotPasswordModalOpen}
          onClose={() => setForgotPasswordModalOpen(false)}
          onBackToLogin={() => {
            setForgotPasswordModalOpen(false);
            setLoginModalOpen(true);
          }}
          onSentInstructions={(email: any) => {
            setForgotPasswordModalOpen(false);
            setSuccessMessage(`Password reset instructions have been sent to ${email}`);
          }}
          prefillEmail={forgotPasswordPrefillEmail}
        />
      )}
    </Box>
  );
}
