import React, { useState } from "react";
import { Box } from "@mui/material";
import { Link, Typography } from "@material-ui/core";

import { Job } from "../../../models";
import "../../../components/ScrollBar.css";
import LoginModal from "../../Login/LoginModal";
import SignUpModal from "../../SignUp/SignUpModal";
import { Colors } from "../../../constants/colors";
import { useAlertStore } from "../../../stores/alert";
import { useMobile, useTab } from "../../../hooks/mobile";
import AvailableBookingCard from "./AvailableBookingCard";
import ForgotPasswordModal from "../../ForgotPassword/ForgotPasswordModal";
import {
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../../components/v2/Styled/enum";

interface Props {
  jobs?: Job[];
  onClick: Function;
  selectedJobId: number;
}

const AvailableBookingCardHolder = (props: Props) => {
  const { jobs, onClick, selectedJobId } = props;
  const isMobile = useMobile();
  const isTab = useTab();
  const boxProps = !(isMobile || isTab) ? { borderLeft: 1, borderRight: 1 } : {};
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [forgotPasswordPrefillEmail, setForgotPasswordPrefillEmail] = useState("");
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const handleLoginClicked = () => {
    setLoginModalOpen(true);
  };

  const handleSignUpClicked = () => {
    setSignUpModalOpen(true);
  };

  return (
    <Box
      {...boxProps}
      borderColor={Colors.Mischka}
      style={{
        display: "flex",
        flexDirection: "column",
        columnGap: Spacing.S4,
        flexGrow: 1,
      }}
      rowGap={isMobile || isTab ? "16px" : "0px"}
      marginY={isMobile || isTab ? "24px" : "0px"}
    >
      {jobs &&
        jobs.map((job: Job) => (
          <Box>
            <AvailableBookingCard
              key={job.id}
              job={job}
              onClick={onClick}
              selectedJobId={selectedJobId}
            />
          </Box>
        ))}
      {jobs && jobs.length <= 3 && (
        <Box padding={Spacing.S6}>
          <Typography
            style={{
              color: Colors.Dusk,
              fontSize: FontSize.F14,
              fontWeight: FontWeight.Regular,
              lineHeight: LineHeight.L21,
              fontFamily: FontFamily.OpenSans,
            }}
          >
            <Link
              onClick={handleLoginClicked}
              style={{ color: Colors.TurquoiseBlue }}
              underline="none"
            >
              Log in
            </Link>{" "}
            to get instant access to all new bookings with detailed information. Dont have an
            account?
            <Link
              onClick={handleSignUpClicked}
              style={{ color: Colors.TurquoiseBlue }}
              underline="none"
            >
              {" "}
              Create account{" "}
            </Link>{" "}
            now!
          </Typography>
        </Box>
      )}
      {/* <AvailableBookingCard/> */}

      <LoginModal
        open={loginModalOpen}
        onClose={() => {
          setLoginModalOpen(false);
        }}
        onLoggedIn={() => {
          setLoginModalOpen(false);
        }}
        onForgotPassword={(email) => {
          setForgotPasswordPrefillEmail(email);
          setLoginModalOpen(false);
          setForgotPasswordModalOpen(true);
        }}
        onCreateAccount={() => {
          setLoginModalOpen(false);
          setSignUpModalOpen(true);
        }}
        redirect={false}
      />

      <SignUpModal
        open={signUpModalOpen}
        onLogin={() => {
          setSignUpModalOpen(false);
          setLoginModalOpen(true);
        }}
        onCreatedAccount={() => {
          setSignUpModalOpen(false);
        }}
        onClose={() => setSignUpModalOpen(false)}
      />

      <ForgotPasswordModal
        open={forgotPasswordModalOpen}
        onClose={() => setForgotPasswordModalOpen(false)}
        onBackToLogin={() => {
          setForgotPasswordModalOpen(false);
          setLoginModalOpen(true);
        }}
        onSentInstructions={(email: any) => {
          setForgotPasswordModalOpen(false);
          setSuccessMessage(`Password reset instructions have been sent to ${email}`);
        }}
        prefillEmail={forgotPasswordPrefillEmail}
      />
    </Box>
  );
};

export default AvailableBookingCardHolder;
